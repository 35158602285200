import React, { FC, memo } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { Modal } from 'shared/ui/Modal';
import { getSpecialPromotionContent } from '../model/selectors/getSpecialPromotionContent';
import { useSpecialPromotionModal } from '../utils/hooks/useSpecialPromotionModal';

export const SpecialPromotionModal: FC = memo(() => {
  const { isOpened, closeModal } = useSpecialPromotionModal();

  const Content = useAppSelector(getSpecialPromotionContent);

  if (!Content) {
    return null;
  }

  return (
    <Modal width={1000} isOpen={isOpened} paddingLess onClose={closeModal}>
      <Content />
    </Modal>
  );
});
