import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { Button } from 'shared/ui/Button';
import BlackFridayBanner from 'shared/assets/images/BlackFridayBanner.png';

export const BlackFridayPromotionContent: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const navigate = useNavigate();

  const { data } = useGetRentOptionsQuery({ isSpecialPromotion: true });

  const { closeModal } = useSpecialPromotionModal();

  const goToBooking = useCallback(() => {
    navigate(data?.length ? `${AppRoutes.BOOKING}?rentOptionId=${data[0].rentOptionId}` : AppRoutes.BOOKING);
    closeModal();
  }, [closeModal, data, navigate]);

  return (
    <div>
      <img src={BlackFridayBanner} alt="Black friday promotion" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white p-7 text-center shadow-xl rounded-xl">
        <div className="mb-2.5 font-bold text-2xl">{t('Dear Customer')}</div>
        <div className="mb-2.5 font-black text-3xl uppercase">{t('Black Friday sale')}</div>
        <div className="mb-8 font-normal text-xl">{t('Start on November 11')}</div>
        <Button theme="dark" onClick={goToBooking}>
          {t('Shop now')}
        </Button>
      </div>
    </div>
  );
});
