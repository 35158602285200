import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { BlackFridayPromotionContent } from 'widgets/BlackFridayPromotion';
import { useSpecialPromotionModal } from '../utils/hooks/useSpecialPromotionModal';
import BlackFridayOpener from 'shared/assets/images/BlackFridayOpener.png';

export const SpecialPromotionOpener: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const { openModal, showSpecialPromotion } = useSpecialPromotionModal();

  const openSpecialPromotionModal = (): void => {
    openModal(BlackFridayPromotionContent);
  };

  if (!showSpecialPromotion) {
    return null;
  }

  return (
    <div
      className="absolute z-50 left-0 right-0 top-0 text-center text-white cursor-pointer bg-gradient-to-r from-[#636363] to-[#252425]"
      onClick={openSpecialPromotionModal}
    >
      <div className="relative flex justify-between items-center px-7 h-14">
        <div className="font-bold text-2xl uppercase">{t('Black Friday Sale')}</div>
        <div className="font-normal text-lg">{t('We have discounts for you. Click to find out')}</div>
        <div className="z-10 font-bold text-2xl text-primary">11.11</div>
        <img className="absolute right-0 h-full" src={BlackFridayOpener} alt="Black friday" />
      </div>
    </div>
  );
});
