import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { MainHeader } from 'widgets/MainHeader';
import { SpecialPromotionOpener, useSpecialPromotionModal } from 'features/SpecialPromotion';

export const MainLayout: FC = memo(() => {
  const { showSpecialPromotion } = useSpecialPromotionModal();

  return (
    <div className={classNames({ 'mt-16': showSpecialPromotion })}>
      <div className="wrapper">
        <SpecialPromotionOpener />
        <MainHeader />
        <main className="mb-16">
          <Outlet />
        </main>
      </div>
    </div>
  );
});
