import { InvoiceFrequencyType } from 'entities/Invoice';

export enum PaymentFrequencySetting {
  ONLY_REGULAR = 'only-regular',
  ONLY_ENTIRE_PERIOD = 'only-entire',
  ALL = 'all',
}

export enum AccountTypeSetting {
  PERSONAL = 'only-personal',
  BUSINESS = 'only-business',
  ALL = 'all',
}

export enum LoyaltyUserSetting {
  ONLY_NEW = 'only-new',
  ONLY_OLD = 'only-old',
  ALL = 'all',
}

export interface RentOptionDocument {
  rentOptionId: string;
  label: string;
  isDefault: boolean;
  isSpecialPromotion: boolean;
  showAsPromotion: boolean;
  invoiceFrequencyType: InvoiceFrequencyType;
  paymentFrequencySetting: PaymentFrequencySetting;
  accountTypeSetting: AccountTypeSetting;
  loyaltyUserSetting: LoyaltyUserSetting;
  minContractDuration: number;
  order: number;
  warehouseIds?: string[];
  discountKeys?: string[];
  description?: string;
  unitSizes: Nullable<string[]>;
}
